import Vue from 'vue'
import Vuex from 'vuex'
import optionsUser from "@/store/modules/optionsUser"
import optionsMenu from "@/store/modules/optionsMenu"
import cookies from "@/store/modules/cookies"
import platformData from "@/store/modules/platformData"
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // 平台信息
    platformData,
    // 用户信息
    optionsUser,
    // 菜单信息
    optionsMenu,
    //cookies信息包含token,
    cookies
  }
})
