import request from "@/utils/request";
/**
 * 设置用户主题配置模板
 * @param
 * @returns {AxiosPromise}
 */
export const setUserTopic = (data) => {
  return request({
    url: "/user/setUserTopic",
    method: "POST",
    data,
  });
};
/**
 * 设置用户主题配置模板
 * @param
 * @returns {AxiosPromise}
 */
export const getUserTopic = () => {
  return request({
    url: "/user//getUserTopic",
    method: "get",
  });
};
