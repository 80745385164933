<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted () {
    window.onresize = () => {
      this.clientWidthResize()
    }
  },
  methods: {
    //获取屏幕宽度放到Vuex里
    clientWidthResize () {
      this.$store.commit('tool/resizeWidth', Number(document.documentElement.clientWidth))
    }
  }
}
</script>

<style>
#app {
  font-family: Source Han Sans SC, Avenir, Helvetica, Arial, sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

/* 单行组合写法溢出隐藏 */
.myhide {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 滚动条样式修改
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(16,31,28,.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(16,31,28,.5);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(#101F1C, 1);
} */
</style>
