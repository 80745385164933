import { MessageBox } from 'element-ui'
class MsgBox {
  constructor() {
    this.isLoging = false
  }
  /**
   * 弹窗
   * @param {Object} param messageBox的参数
   */
  dialog(param) {
    if (!this.isLoging) {
      this.isLoging = true
      const _this = this
      MessageBox.confirm(param.message,param.title,{
        confirmButtonText:param.confirmButtonText,
        showClose:param.showClose,
        showCancelButton:param.showCancelButton,
        closeOnClickModal:param.closeOnClickModal,
        closeOnPressEscape:param.closeOnPressEscape,
        type:param.type,
        callback () {
          param.callback()
          _this.isLoging = false // 点击确认关闭弹窗后 重置
        }
      })
    }
  }
}
 
export const msgBox = new MsgBox()