export default {
  namespaced: true,
  actions: {

  },
  mutations: {
    setPlatformData(state, value) {
      console.log(value)
      state.platformData = value;
    },
  },
  state: {
    platformData: {},
  },
  getters: {

  }
}