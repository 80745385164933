//引入ElementUI组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as util from '@/utils/util'
//全局初始化样式
import 'normalize.css'
import '@/styles/index.scss'

let MyPlugin = {
  install: function (Vue) {
    //全局注册一个宽度
    Vue.prototype.labelWidth = '120px'
    //引入ui框架
    Vue.use(ElementUI);
    // 工具函数
    Vue.prototype.util = util
  }
}
export default MyPlugin
