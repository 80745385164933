import { setUserTopic } from "@/api/preview/index.js";
export default {
  namespaced: true,
  actions: {
    // 获取用户信息并设置
    //获取权限信息并设置
    async setcolorTheme(context, value) {
      let data = {
        topicType: 0,
        topicName: "默认模板",
        topicDesc: "默认模板",
        topicProperFont: "normal",
        topicColour: value,
        deleted: 0,
      };
      let res = await setUserTopic(data);
      if (res.success) {
        context.commit("setcolorTheme", value);
      }
    },
  },
  mutations: {
    setuserInfo(state, value) {
      state.userInfo = value;
    },
    setcolorTheme(state, value) {
      state.colorTheme = value;
    },
  },
  state: {
    userInfo: {},
    colorTheme: null,
  },
  getters: {
    getColorTheme(state) {
      return state.colorTheme;
    },
    getuserInfoArr(state) {
      return Object.keys(state.userInfo);
    },
    getUserUnitType(state) {
      return state.userInfo?.unitType;
    },
    getUserExtra(state) {
      return state.userInfo?.extra;
    },
    getUserUnitId(state) {
      return state.userInfo?.unitId;
    },
    getUserUnitName(state) {
      return state.userInfo.unitName;
    },
    getUserAccount(state) {
      return state.userInfo.account;
    },
    getUserCellphone(state) {
      return state.userInfo.cellphone;
    },
    getUserUserId(state) {
      return state.userInfo.userId;
    },
    getPlatformData(state) {
      return state.userInfo.unitInfo || {};
    },
  },
};
